<template>
<v-main class="container-prosker">
    <v-data-table :options.sync="pagination" :server-items-length="pagination.totalItems" :loading="loadingTable" :search="search" :headers="getHeaders" :footer-props="{itemsPerPageText: $t('rowsPerPage')}" :items="servicesData" item-key="id" class="elevation-1 mt-5">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{$t('adminUsers')}}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical />
                <v-row class="w-100">
                    <v-col cols="4" sm="2">
                        <v-select class="ml-2" hide-details color="#59D79D" v-model="selectedOption" :items="getUserFilter" :label="$t('typeUser')" :item-text="'name'" :item-value="'type_user'" @change="list" />
                    </v-col>
                    <v-col cols="6" sm="8">
                        <v-text-field :label="$t('search')" append-icon="search" hide-details single-line v-model="search" color="#59D79D" />
                    </v-col>
                    <v-col cols="2" class="text-end">
                        <user-dialog :item="null" :on-close="onAddClose" :show-add="showAdd" :is-edit=false :new-item="showUserDialog">
                            <template v-slot:customButton="{ on, click }">
                                <v-btn @click="click" color="#59D79D" dark v-on="on">
                                    {{ $vuetify.breakpoint.mobile ? '+' : $t("createUser") }}
                                </v-btn>
                            </template>
                        </user-dialog>
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
            </v-toolbar>
        </template>
        <template v-slot:no-data>
            <v-alert :value="true" color="error" icon="warning" class="white--text">
                <span class="white--text">
                    {{ $t("noData") }}
                </span>
            </v-alert>
        </template>
        <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
                <span class="white--text">
                    {{ $t('yourSearchFor') }} "{{ search }}" {{ $t('foundNoResults') }}
                </span>
            </v-alert>
        </template>
        <template v-slot:item.first_name="{ item }">
            <span class="caption">{{ item.first_name }}</span>
        </template>
        <template v-slot:item.lastName="{ item }">
            <span class="caption">{{ item.last_name }}</span>
        </template>
        <template v-slot:item.email="{ item }">
            <span class="caption">{{ item.email }}</span>
        </template>
        <template v-slot:item.phoneNumber="{ item }">
            <span class="caption">{{ item.phone_number }}</span>
        </template>
        <template v-slot:item.active_subscription[0].name="{ item }">
            <span class="caption">{{ item.active_subscription[0]?.name }} {{ item.active_subscription[0]?.expiry_date ? `(${formatDate(item.active_subscription[0]?.expiry_date)})` : '' }}</span>
        </template>
        <template v-slot:item.last_log_in="{ item }">
            <span class="caption">{{ item.last_log_in }}</span>
        </template>
        <template v-slot:item.created_at="{ item }">
            <span class="caption">{{ item.created_at }}</span>
        </template>
        <template v-slot:item.percentage="{ item }">
            <span class="caption">{{ item.percentage }}%</span>
        </template>
        <template v-slot:item.type_user="{ item }">
            <span class="caption">{{ translateUserType(item.type_user) }}</span>
        </template>
        <template v-slot:item.action="{ item }">
            <tooltip-button :icon="'mdi-pencil'" :action="editItem" :item="item" :color="'secondary'" :message="'edit'"></tooltip-button>
        </template>
    </v-data-table>
</v-main>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import getStatusJobsName from '@/components/socialvue/mixins/getStatusJobsName';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import moment from 'moment';
import {
    USER_TYPE_PROSKER,
    USER_TYPE_CLIENT,
    USER_TYPE_ADMIN
} from '@/misc/constants';
import UserDialog from '@/components/socialvue/dialogs/UserDialog';
import TooltipButton from '@/components/socialvue/buttons/TooltipButton.vue';
export default {
    name: 'UserInformation',
    components: {
        UserDialog,
        TooltipButton
    },
    mixins: [errorDialog, getStatusJobsName],
    data () {
        return {
            servicesData: [],
            isDirty: false,
            pagination: {
                totalItems: 0,
                per_page: 10,
                page: 1
            },
            loadingTable: false,
            search: '',
            loading: false,
            id: 0,
            selectedOption: null,
            showAdd: false
        };
    },
    created () {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
        this.selectedOption = this.getUserFilter[0].type_user;
        this.callBack();
    },
    computed: {
        getHeaders () {
            return [{
                    text: this.$t('firstName'),
                    value: 'first_name',
                    width: '15%'
                },
                {
                    text: this.$t('lastName'),
                    value: 'last_name',
                    width: '15%'
                },
                {
                    text: this.$t('email'),
                    value: 'email'
                },
                {
                    text: this.$t('phoneNumber'),
                    value: 'phone_number'
                },
                {
                    text: this.$t('planDetails'),
                    value: 'active_subscription[0].name'
                },
                {
                    text: this.$t('typeUser'),
                    value: 'type_user'
                },
                {
                    text: this.$t('lastLogin'),
                    value: 'last_log_in'
                },
                {
                    text: this.$t('registerDate'),
                    value: 'created_at'
                },
                {
                    text: this.$t('percentage'),
                    value: 'percentage'
                },
                {
                    text: this.$t('actions'),
                    value: 'action',
                    sortable: false
                }
            ];
        },
        getUserFilter () {
            return [{
                    type_user: false,
                    name: 'All'
                },
                {
                    type_user: USER_TYPE_PROSKER,
                    name: this.$t('prosker')
                },
                {
                    type_user: USER_TYPE_CLIENT,
                    name: this.$t('client')
                },
                {
                    type_user: USER_TYPE_ADMIN,
                    name: this.$t('admin')
                }
            ]
        }
    },
    watch: {
        'search': {
            handler () {
                this.resetPaginationForSearch(this);
            },
            deep: true
        },
        'pagination': {
            handler () {
                if (!this.isDirty) {
                    this.list();
                }
            },
            deep: true
        }
    },
    methods: {
        formatDate (date) {
            return moment(date).format('DD-MM-YYYY');
        },
        editItem (item) {
            this.$router.push({
                name: 'social.users-information.edit-user',
                params: {
                    id: item?.id
                }
            });
        },
        translateUserType (type) {
            switch (type) {
                case USER_TYPE_PROSKER:
                    return this.$t('prosker');
                case USER_TYPE_CLIENT:
                    return this.$t('client');
                case USER_TYPE_ADMIN:
                    return this.$t('admin');
                default:
                    return 'UNKNOWN';
            }
        },
        callBack () {
            this.list();
        },
        resetPagination () {
            if (!this.isDirty) {
                this.pagination = {
                    totalItems: 0,
                    per_page: 10,
                    page: 1,
                    sortBy: ['id']
                };
            }
        },
        onAddClose () {
            this.showAdd = false;
        },
        showUserDialog () {
            this.showAdd = true;
        },
        resetPaginationForSearch: _.debounce((self) => {
            if (!self.isDirty) {
                self.resetPagination();
            }
        }, 500),
        list () {
            this.isDirty = true;
            this.servicesData = [];
            this.loadingTable = true;

            const filterOption = (this.selectedOption !== null && this.selectedOption !== undefined &&
                this.selectedOption !== false) || this.selectedOption === 0 ? this.selectedOption === 0
                    ? `filters=type_user=${this.selectedOption}|`
                    : `filters=type_user=${this.selectedOption}` : '';

            const perPage = this.pagination.itemsPerPage
                ? this.pagination.itemsPerPage === -1
                    ? this.pagination.totalItems
                        : this.pagination.itemsPerPage : 10;

            const getProfileCompletionPercentage = (user) => {
                let percentage = 100;
                if (user.type_user >= USER_TYPE_PROSKER) {
                    if (!user.profile_img) {
                        percentage -= 14; // 100/7
                    }
                    if (!user.first_name || !user.last_name) {
                        percentage -= 14;
                    }
                    if (user.category_name.length === 0) {
                        percentage -= 14;
                    }
                    if (user.skill_name.length === 0) {
                        percentage -= 14;
                    }
                    if (!user.banner_img) {
                        percentage -= 14;
                    }
                    if (user.addresses.length === 0) {
                        percentage -= 14;
                    }
                    if (user.type_user >= USER_TYPE_PROSKER && !user.mp_access_token) {
                        percentage -= 14;
                    }
                } else {
                    if (!user.profile_img) {
                        percentage -= 50; // 100/2
                    }
                    if (!user.first_name || !user.last_name) {
                        percentage -= 50;
                    }
                }
                return percentage;
            };

            axios.get(`${process.env.VUE_APP_BASE_URL}/api/users?${filterOption}${this.search.trim().length !== 0 ? `
                &search=${this.search}` : ''}&page=${this.pagination.page}&per_page=${perPage}&sortBy=created_at,desc`)
                    .then((response) => {
                        this.isDirty = false;
                        response.data.data.forEach((userItem) => {
                            userItem.last_log_in = userItem.last_log_in ? moment(userItem.last_log_in).format('DD-MM-YYYY') : this.$t('noData');
                            userItem.created_at = userItem.created_at ? moment(userItem.created_at).format('DD-MM-YYYY') : this.$t('noData');
                            userItem.percentage = getProfileCompletionPercentage(userItem);
                            this.servicesData.push(userItem);
                        });
                        this.pagination.totalItems = response.data.total;
                        this.loadingTable = false;
                    }).catch((error) => {
                        this.isDirty = false;
                        this.loadingTable = false;
                        this.showError(error);
                    });
        }
    }
};
</script>
